<template>
  <main>
    <div class="container">
      <div
        class="col-sm-8 mx-auto py-5 mt-5 justify-content-center"
        style="min-height: 60vh"
      >
        <div class="message-box text- p-4 rounded-lg shadow-mdn py-5">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-">Quelle est la prochaine étape?</h4>

                <div class="text-muted mb-4">
                  <p class="">
                    Vous venez de vous inscrire avec succès. Un lien vous a été envoyé par
                    courriel à l’adresse électronique que vous avez indiquée, afin de vous
                    permettre de finaliser votre dossier et de valider votre compte auprès
                    de l’ANPE.
                  </p>

                  <p>
                    Par ailleurs, nous vous prions de bien vouloir prendre contact avec un
                    Conseiller en Emploi au niveau de l’antenne de l’ANPE de votre région
                    ou avec le Responsable de l’Unité Locale de Promotion de l’Emploi
                    (ULPE) à la mairie pour finaliser votre enregistrement. Ce sera
                    l’occasion de mieux apprécier vos besoins pour mieux vous servir.
                  </p>

                  <p>
                    Pour toute préoccupation, nous vous invitons à contacter les
                    numéros suivants : 
                    <div>(gratuit) : <span  class="fw-bold text-">+229 01 5110 3333</span> </div>
                    <div class="fw-bold text-">(payant) : <span class="fw-bold text-">+229 01 5110 6666</span></div>
                    <p class="mt-2">L'ANPE, au service de l'emploi et des compétences !</p>
                  </p>
                </div>
                <h6 v-if="hasValidEmail" class="text-muted mb-4">
                  Un lien vous a été envoyé par courriel pour la validation de votre
                  compte.
                </h6>
                <a href="/" class="btn btn-primary">Retourner à la page d'acceuil</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    estPeuOuPasInstruit() {
      return this.$route.query.ppi;
    },
    hasValidEmail() {
      return this.$route.hasEmail;
    },
  },
};
</script>

<style>
.message-box {
  background: #aef0ab45 !important;
}
</style>
